import React, { createContext, useReducer } from "react";

const initialState: iInitialState = {
    bucketRegion: "eu-central-1",
    imageUrl: "",
    imageName: "",
    bucketName: "image-to-caption-uploadstore",
    //params:
    bildAusschnitt: "",
    gender: "",
    race: "",
    clothes: "",
    skinColor: "",
    hairColor: "",
    age: "",
    scene: "",
    eyes: "",
    bodyType: "",
    activity: "",

    responseLoading: false,
    getCaptionButtonDisabled: true,
    imageUploading: false,
    imageUploaded: false,
    responseCaption: "",
    notificationBarHidden: false,
    //account:
    username: "",
    stripeEmail: "",
    subscriptionStatus: "not subscribed",
    periodStart: "",
    periodEnd: "",
    periodDaysLeft: 0,
    plan: "",
    paymentPeriod: "monthly",
    checkingSubscriptionStatus: true,
    referalId: "",
};
interface iInitialState {
    bucketRegion: string,
    imageUrl: string,
    imageName: string,
    bucketName: string,
    //params:
    bildAusschnitt: string;
    gender: string;
    race: string;
    clothes: string;
    skinColor: string;
    hairColor: string;
    age: string;
    scene: string;
    eyes: string;
    bodyType: string;
    activity: string;

    responseLoading: boolean,
    getCaptionButtonDisabled: boolean,
    imageUploading: boolean,
    imageUploaded: boolean,
    responseCaption: string,
    notificationBarHidden: boolean,

    //account:
    username: string,
    stripeEmail: string,
    subscriptionStatus: string,
    periodStart: string,
    periodEnd: string,
    periodDaysLeft: number,
    plan: string,
    paymentPeriod: string,
    checkingSubscriptionStatus: boolean,
    referalId: string,
}
const defaultDispatch: React.Dispatch<Action> = () => initialState;

export const ModelContext = createContext(initialState);
export const ModelDispatchContext = createContext(defaultDispatch);

type Action =
    | { type: 'CHANGE_URL', imageUrl: string }
    | { type: 'CHANGE_LOADING', responseLoading: boolean }
    | { type: 'CHANGE_CAPTION', responseCaption: string }
    | { type: "CHANGE_FILENAME", imageName: string }
    | { type: "CHANGE_BUTTON", getCaptionButtonDisabled: boolean }
    //params:
    | { type: "CHANGE_BILDAUSSCHNITT", bildAusschnitt: string }
    | { type: "CHANGE_GENDER", gender: string }
    | { type: "CHANGE_RACE", race: string }
    | { type: "CHANGE_CLOTHES", clothes: string }
    | { type: "CHANGE_SKIN_COLOR", skinColor: string }
    | { type: "CHANGE_HAIR_COLOR", hairColor: string }
    | { type: "CHANGE_AGE", age: string }
    | { type: "CHANGE_SCENE", scene: string }
    | { type: "CHANGE_EYES", eyes: string }
    | { type: "CHANGE_BODY_TYPE", bodyType: string }
    | { type: "CHANGE_ACTIVITY", activity: string }

    | { type: "UPLOAD", imageUploading: boolean, imageUploaded: boolean }
    | { type: "RESET" }
    | { type: 'CHANGE_NOTIFICATIONBAR_HIDDEN', notificationBarHidden: boolean }
    //acount:
    | { type: 'CHANGE_USERNAME', username: string }
    | { type: 'CHANGE_STRIPE_EMAIL', stripeEmail: string }
    | { type: 'CHANGE_SUBSCRIPTION_STATUS', subscriptionStatus: string }
    | { type: 'CHANGE_PERIOD_START', periodStart: string }
    | { type: 'CHANGE_PERIOD_END', periodEnd: string }
    | { type: 'CHANGE_PERIOD_DAYS_LEFT', periodDaysLeft: number }
    | { type: 'CHANGE_PLAN', plan: string }
    | { type: 'CHANGE_CHECKING_SUBSCRIPTION_STATUS', checkingSubscriptionStatus: boolean }
    | { type: 'CHANGE_REFERAL_ID', referalId: string }
    | { type: 'CHANGE_PAYMENT_PERIOD', paymentPeriod: string };

function reducer(state: any, action: Action) {
    switch (action.type) {
        case "CHANGE_URL":
            return { ...state, imageUrl: action.imageUrl };
        case "CHANGE_LOADING":
            return { ...state, responseLoading: action.responseLoading };
        case "CHANGE_CAPTION":
            return { ...state, responseCaption: action.responseCaption };
        case "CHANGE_FILENAME":
            return { ...state, imageName: action.imageName };
        case "CHANGE_BUTTON":
            return { ...state, getCaptionButtonDisabled: action.getCaptionButtonDisabled };
        //params:
        case "CHANGE_BILDAUSSCHNITT":
            return { ...state, bildAusschnitt: action.bildAusschnitt };
        case "CHANGE_GENDER":
            return { ...state, gender: action.gender };
        case "CHANGE_RACE":
            return { ...state, race: action.race };
        case "CHANGE_CLOTHES":
            return { ...state, clothes: action.clothes };
        case "CHANGE_SKIN_COLOR":
            return { ...state, skinColor: action.skinColor };
        case "CHANGE_HAIR_COLOR":
            return { ...state, hairColor: action.hairColor };
        case "CHANGE_AGE":
            return { ...state, age: action.age };
        case "CHANGE_EYES":
            return { ...state, eyes: action.eyes };
        case "CHANGE_BODY_TYPE":
            return { ...state, bodyType: action.bodyType };
        case "CHANGE_SCENE":
            return { ...state, scene: action.scene };
        case "CHANGE_ACTIVITY":
            return { ...state, activity: action.activity };

        case "UPLOAD":
            return { ...state, imageUploading: action.imageUploading, imageUploaded: action.imageUploaded };
        case "RESET":
            return { ...state, imageUrl: "", imageName: "", responseLoading: false, responseCaption: "", getCaptionButtonDisabled: true, imageUploading: false, imageUploaded: false };
        case "CHANGE_NOTIFICATIONBAR_HIDDEN":
            return { ...state, notificationBarHidden: action.notificationBarHidden };

        //account:
        case "CHANGE_USERNAME":
            return { ...state, username: action.username };
        case "CHANGE_SUBSCRIPTION_STATUS":
            return { ...state, subscriptionStatus: action.subscriptionStatus };
        case "CHANGE_PERIOD_START":
            return { ...state, periodStart: action.periodStart };
        case "CHANGE_PERIOD_END":
            return { ...state, periodEnd: action.periodEnd };
        case "CHANGE_PERIOD_DAYS_LEFT":
            return { ...state, periodDaysLeft: action.periodDaysLeft };
        case "CHANGE_PLAN":
            return { ...state, plan: action.plan };
        case "CHANGE_PAYMENT_PERIOD":
            return { ...state, paymentPeriod: action.paymentPeriod };
        case "CHANGE_STRIPE_EMAIL":
            return { ...state, stripeEmail: action.stripeEmail };
        case "CHANGE_CHECKING_SUBSCRIPTION_STATUS":
            return { ...state, checkingSubscriptionStatus: action.checkingSubscriptionStatus };
        case "CHANGE_REFERAL_ID":
            return { ...state, referalId: action.referalId };
        default:
            return state;
    }
}

export function ImageProvider(props: { children: any; }) {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <ModelContext.Provider value={state}>
            <ModelDispatchContext.Provider value={dispatch}>
                {props.children}
            </ModelDispatchContext.Provider>
        </ModelContext.Provider>
    )
}