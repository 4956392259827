import { useContext } from 'react';
import { FormControl, Box, Autocomplete, TextField, Stack, useMediaQuery, useTheme } from '@mui/material';
import { ModelContext, ModelDispatchContext } from '../../../context/ModelContext';

const CaptionOptions = () => {
    const { bildAusschnitt, gender, race, clothes, age, hairColor, scene, eyes, bodyType, activity } = useContext(ModelContext);
    const dispatch = useContext(ModelDispatchContext);

    const reactTheme = useTheme();
    const isMobile = useMediaQuery(reactTheme.breakpoints.down('sm'));

    const handleBildAuschnittChange = (value: string) => {
        dispatch({ type: 'CHANGE_BILDAUSSCHNITT', bildAusschnitt: value });
    };
    const handleGenderChange = (value: string) => {
        dispatch({ type: 'CHANGE_GENDER', gender: value });
    };
    const handleRaceChange = (value: string) => {
        dispatch({ type: 'CHANGE_RACE', race: value });
    };
    const handleClothesChange = (value: string) => {
        dispatch({ type: 'CHANGE_CLOTHES', clothes: value });
    };
    const handleHairColorChange = (value: string) => {
        dispatch({ type: 'CHANGE_HAIR_COLOR', hairColor: value });
    };
    const handelAgeChange = (value: string) => {
        dispatch({ type: 'CHANGE_AGE', age: value });
    };
    const handleEyesChange = (value: string) => {
        dispatch({ type: 'CHANGE_EYES', eyes: value });
    };
    const handleBodyChange = (value: string) => {
        dispatch({ type: 'CHANGE_BODY_TYPE', bodyType: value });
    };
    const handleSceneChange = (value: string) => {
        dispatch({ type: 'CHANGE_SCENE', scene: value });
    }
    const handleActivityChange = (value: string) => {
        dispatch({ type: 'CHANGE_ACTIVITY', activity: value });
    }

    return (
        <Box>
            {isMobile ?
                <Stack direction="column" spacing={1}>

                    <FormControl sx={{ pb: 2, minWidth: 200 }}>
                        <Autocomplete
                            freeSolo
                            options={['portrait', 'facial close-up', 'full-body (experimental)',]}
                            value={bildAusschnitt}
                            onChange={(event, newValue) => { handleBildAuschnittChange(newValue) }}
                            onInputChange={(event, newInputValue) => { handleBildAuschnittChange(newInputValue) }}
                            renderInput={(params) => <TextField {...params} label="display detail" />}
                        />
                    </FormControl>

                    <FormControl sx={{ pb: 2, minWidth: 200 }}>
                        <Autocomplete
                            freeSolo
                            options={['female', 'male', 'male and female', 'male and male', 'female and female']}
                            value={gender}
                            onChange={(event, newValue) => { handleGenderChange(newValue) }}
                            onInputChange={(event, newInputValue) => { handleGenderChange(newInputValue) }}
                            renderInput={(params) => <TextField {...params} label="Gender *" />}
                        />
                    </FormControl>

                    <FormControl sx={{ pb: 2, minWidth: 200 }}>
                        <Autocomplete
                            freeSolo
                            options={['white', 'east asian (Chinese, japanese, korean)', 'south east asian (Thai, indonesian)', 'african', 'middle eastern', 'hispanic', 'indian', 'native american', 'pacific islander', 'indian and white']}
                            value={race}
                            onChange={(event, newValue) => { handleRaceChange(newValue) }}
                            onInputChange={(event, newInputValue) => { handleRaceChange(newInputValue) }}
                            renderInput={(params) => <TextField {...params} label="Ethnic group *" />}
                        />
                    </FormControl>

                    <FormControl sx={{ pb: 2, minWidth: 200 }}>
                        <Autocomplete
                            freeSolo
                            options={['baby', 'child', 'teenager', 'young adult', '30-40', '40-50', '50-60', 'over 60']}
                            value={age}
                            onChange={(event, newValue) => { handelAgeChange(newValue) }}
                            onInputChange={(event, newInputValue) => { handelAgeChange(newInputValue) }}
                            renderInput={(params) => <TextField {...params} label="Age" />}
                        />
                    </FormControl>

                    <FormControl sx={{ pb: 2, minWidth: 200 }}>
                        <Autocomplete
                            freeSolo
                            options={['casual', 'business', 'formal', 'sport', 'biker', 'business casual', 'boho', 'hipster', 'goth', 'punk', 'skater', 'surfer', 'vintage', 'western', 'cosplay', 'rainbow', 'maternity']}
                            value={clothes}
                            onChange={(event, newValue) => { handleClothesChange(newValue) }}
                            onInputChange={(event, newInputValue) => { handleClothesChange(newInputValue) }}
                            renderInput={(params) => <TextField {...params} label="Clothing *" />}
                        />
                    </FormControl>


                    <FormControl sx={{ pb: 2, minWidth: 200 }}>
                        <Autocomplete
                            freeSolo
                            options={['blonde', 'brown', 'red', 'black', 'grey', 'white']}
                            value={hairColor}
                            onChange={(event, newValue) => { handleHairColorChange(newValue) }}
                            onInputChange={(event, newInputValue) => { handleHairColorChange(newInputValue) }}
                            renderInput={(params) => <TextField {...params} label="Hair color" />}
                        />
                    </FormControl>

                    <FormControl sx={{ pb: 2, minWidth: 200 }}>
                        <Autocomplete
                            freeSolo
                            options={['blonde', 'brown', 'red', 'black', 'grey', 'white']}
                            value={hairColor}
                            onChange={(event, newValue) => { handleHairColorChange(newValue) }}
                            onInputChange={(event, newInputValue) => { handleHairColorChange(newInputValue) }}
                            renderInput={(params) => <TextField {...params} label="Hair color" />}
                        />
                    </FormControl>

                    <FormControl sx={{ pb: 2, minWidth: 200 }}>
                        <Autocomplete
                            freeSolo
                            options={['blue', 'green', 'brown', 'grey', 'hazel', 'black']}
                            value={eyes}
                            onChange={(event, newValue) => { handleEyesChange(newValue) }}
                            onInputChange={(event, newInputValue) => { handleEyesChange(newInputValue) }}
                            renderInput={(params) => <TextField {...params} label="Eye color" />}
                        />
                    </FormControl>

                    <FormControl sx={{ pb: 2, minWidth: 200 }}>
                        <Autocomplete
                            freeSolo
                            options={['slim', 'athletic', 'average', 'curvy', 'muscular', 'heavyset']}
                            value={bodyType}
                            onChange={(event, newValue) => { handleBodyChange(newValue) }}
                            onInputChange={(event, newInputValue) => { handleBodyChange(newInputValue) }}
                            renderInput={(params) => <TextField {...params} label="Body type" />}
                        />
                    </FormControl>

                    <FormControl sx={{ pb: 2, minWidth: 200 }}>
                        <Autocomplete
                            freeSolo
                            options={['city', 'forrest', 'beach', 'office', 'church', 'temple', 'cafe', 'home']}
                            value={scene}
                            onChange={(event, newValue) => { handleSceneChange(newValue) }}
                            onInputChange={(event, newInputValue) => { handleSceneChange(newInputValue) }}
                            renderInput={(params) => <TextField {...params} label="Scene" />}
                        />
                    </FormControl>
                    <FormControl sx={{ pb: 2, minWidth: 200 }}>
                        <Autocomplete
                            freeSolo
                            options={['running', 'working on a laptop', 'drinking coffee', 'drinking wine', 'eating', 'shopping', 'playing guitar', 'playing piano']}
                            value={activity}
                            onChange={(event, newValue) => { handleActivityChange(newValue) }}
                            onInputChange={(event, newInputValue) => { handleActivityChange(newInputValue) }}
                            renderInput={(params) => <TextField {...params} label="Activity" />}
                        />
                    </FormControl>

                </Stack> :
                <>

                    <Stack direction="row" spacing={2} justifyContent={'center'}>

                        <FormControl sx={{ pb: 2, minWidth: 200 }}>
                            <Autocomplete
                                freeSolo
                                options={['portrait', 'facial close-up', 'full-body (experimental)',]}
                                value={bildAusschnitt}
                                onChange={(event, newValue) => { handleBildAuschnittChange(newValue) }}
                                onInputChange={(event, newInputValue) => { handleBildAuschnittChange(newInputValue) }}
                                renderInput={(params) => <TextField {...params} label="display detail" />}
                            />
                        </FormControl>

                        <FormControl sx={{ pb: 2, minWidth: 200 }}>
                            <Autocomplete
                                freeSolo
                                options={['female', 'male', 'male and female', 'male and male', 'female and female']}
                                value={gender}
                                onChange={(event, newValue) => { handleGenderChange(newValue) }}
                                onInputChange={(event, newInputValue) => { handleGenderChange(newInputValue) }}
                                renderInput={(params) => <TextField {...params} label="Gender *" />}
                            />
                        </FormControl>

                        <FormControl sx={{ pb: 2, minWidth: 200 }}>
                            <Autocomplete
                                freeSolo
                                options={['white', 'east asian (Chinese, japanese, korean)', 'south east asian (Thai, indonesian)', 'african', 'middle eastern', 'hispanic', 'indian', 'native american', 'pacific islander', 'indian and white']}
                                value={race}
                                onChange={(event, newValue) => { handleRaceChange(newValue) }}
                                onInputChange={(event, newInputValue) => { handleRaceChange(newInputValue) }}
                                renderInput={(params) => <TextField {...params} label="Ethnic group *" />}
                            />
                        </FormControl>


                    </Stack>

                    <Stack direction="row" spacing={2} justifyContent={'center'}>

                        <FormControl sx={{ pb: 2, minWidth: 200 }}>
                            <Autocomplete
                                freeSolo
                                options={['baby', 'child', 'teenager', 'young adult', '30-40', '40-50', '50-60', 'over 60']}
                                value={age}
                                onChange={(event, newValue) => { handelAgeChange(newValue) }}
                                onInputChange={(event, newInputValue) => { handelAgeChange(newInputValue) }}
                                renderInput={(params) => <TextField {...params} label="Age" />}
                            />
                        </FormControl>

                        <FormControl sx={{ pb: 2, minWidth: 200 }}>
                            <Autocomplete
                                freeSolo
                                options={['casual', 'business', 'formal', 'sport', 'biker', 'business casual', 'boho', 'hipster', 'goth', 'punk', 'skater', 'surfer', 'vintage', 'western', 'cosplay', 'rainbow', 'maternity']}
                                value={clothes}
                                onChange={(event, newValue) => { handleClothesChange(newValue) }}
                                onInputChange={(event, newInputValue) => { handleClothesChange(newInputValue) }}
                                renderInput={(params) => <TextField {...params} label="Clothing *" />}
                            />
                        </FormControl>


                        <FormControl sx={{ pb: 2, minWidth: 200 }}>
                            <Autocomplete
                                freeSolo
                                options={['blonde', 'brown', 'red', 'black', 'grey', 'white', 'bald']}
                                value={hairColor}
                                onChange={(event, newValue) => { handleHairColorChange(newValue) }}
                                onInputChange={(event, newInputValue) => { handleHairColorChange(newInputValue) }}
                                renderInput={(params) => <TextField {...params} label="Hair color" />}
                            />
                        </FormControl>

                    </Stack>
                    <Stack direction="row" spacing={2} justifyContent={'center'}>
                        <FormControl sx={{ pb: 2, minWidth: 200 }}>
                            <Autocomplete
                                freeSolo
                                options={['blue', 'green', 'brown', 'grey', 'hazel', 'black']}
                                value={eyes}
                                onChange={(event, newValue) => { handleEyesChange(newValue) }}
                                onInputChange={(event, newInputValue) => { handleEyesChange(newInputValue) }}
                                renderInput={(params) => <TextField {...params} label="Eye color" />}
                            />
                        </FormControl>

                        <FormControl sx={{ pb: 2, minWidth: 200 }}>
                            <Autocomplete
                                freeSolo
                                options={['slim', 'athletic', 'average', 'curvy', 'muscular', 'heavyset']}
                                value={bodyType}
                                onChange={(event, newValue) => { handleBodyChange(newValue) }}
                                onInputChange={(event, newInputValue) => { handleBodyChange(newInputValue) }}
                                renderInput={(params) => <TextField {...params} label="Body type" />}
                            />
                        </FormControl>

                        <FormControl sx={{ pb: 2, minWidth: 200 }}>
                            <Autocomplete
                                freeSolo
                                options={['city', 'forrest', 'beach', 'office', 'church', 'temple', 'cafe', 'home']}
                                value={scene}
                                onChange={(event, newValue) => { handleSceneChange(newValue) }}
                                onInputChange={(event, newInputValue) => { handleSceneChange(newInputValue) }}
                                renderInput={(params) => <TextField {...params} label="Scene" />}
                            />
                        </FormControl>

                    </Stack>
                    <FormControl sx={{ pb: 2, minWidth: 200 }}>
                        <Autocomplete
                            freeSolo
                            options={['running', 'working on a laptop', 'drinking coffee', 'drinking wine', 'eating', 'shopping', 'playing guitar', 'playing piano']}
                            value={activity}
                            onChange={(event, newValue) => { handleActivityChange(newValue) }}
                            onInputChange={(event, newInputValue) => { handleActivityChange(newInputValue) }}
                            renderInput={(params) => <TextField {...params} label="Activity" />}
                        />
                    </FormControl>
                </>
            }

        </Box >
    );
};

export default CaptionOptions;
