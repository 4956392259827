import { Button } from "@mui/material";
import { useContext } from "react";
import { ModelContext } from "../../context/ModelContext";
import { API } from "aws-amplify";

export const PurchaseButton = () => {
    const { username, paymentPeriod } = useContext(ModelContext);

    const handleCheckout = async (event: any) => {
        event.preventDefault();

        const apiName = 'paymentapi';
        const path = '/payment/create-checkout-session';
        const myInit = {
            body: {
                username: username,
                period: paymentPeriod,
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };
        API.post(apiName, path, myInit).then((response) => {
            window.location.href = response.url;
        }).catch((error) => {
            console.log(error);
        })
    };

    return (
        <>
            <form onSubmit={handleCheckout} method="POST">
                <Button style={{ backgroundColor: '#ff7a8a', color: "white", width: "200px", marginRight: 2 }} type="submit">
                    Start subscription
                </Button>
            </form >
        </>
    )
}