import { Box, Grid, Typography } from "@mui/material"
import { useMediaQuery, useTheme } from "@mui/material";
import { ModelGenCard } from "../ModelGenCard";
import img1 from '../../images/1.png';
import img2 from '../../images/2.png';
import img3 from '../../images/3.png';
import img4 from '../../images/4.png';
import img5 from '../../images/5.png';
import img6 from '../../images/6.png';
import img7 from '../../images/7.png';
import img8 from '../../images/8.png';
import img9 from '../../images/9.png';
import img10 from '../../images/10.png';
import img11 from '../../images/11.png';
import img12 from '../../images/12.png';
import img13 from '../../images/13.png';
import img14 from '../../images/14.png';
import img15 from '../../images/15.png';
import img16 from '../../images/16.png';
import img17 from '../../images/17.png';
import img18 from '../../images/18.png';
import img19 from '../../images/19.png';
import img20 from '../../images/20.png';

export const SoFar = () => {
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));


    return (
        <Box>
            {(isMobile ?
                <Grid container spacing={4} alignItems="center" justifyContent="center" direction="row">
                    <Grid item>
                        <ModelGenCard imageSrc={img1} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img2} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img3} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img4} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img5} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img7} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img6} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img8} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img10} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img11} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img9} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img12} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img13} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img14} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img15} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img16} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img18} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img17} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img19} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img20} />
                    </Grid>
                </Grid>
                :
                <Grid container spacing={4} alignItems="center" justifyContent="center" direction="row">
                    <Grid item>
                        <ModelGenCard imageSrc={img1} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img2} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img3} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img4} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img5} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img7} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img6} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img8} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img10} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img11} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img9} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img12} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img13} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img14} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img15} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img16} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img18} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img17} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img19} />
                    </Grid>
                    <Grid item>
                        <ModelGenCard imageSrc={img20} />
                    </Grid>
                </Grid>
            )}
        </Box >
    )
}

