import SubmitButton from "./components/SubmitButton";
import CaptionOptions from "./components/captionOptions";
import { useContext, useEffect, useState } from "react";
import { ModelContext, ModelDispatchContext } from "../../context/ModelContext";
import ImagePreview from "./components/image-preview";
import { Authenticator } from "@aws-amplify/ui-react";
import { Box, Button, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import { API, Auth } from 'aws-amplify';
import {
    ThemeProvider as AmplifyThemeProvider,
    useTheme as useAmplifyTheme,
    Theme as AmplifyTheme,
} from "@aws-amplify/ui-react";
import { PurchaseButton } from "../account/purchaseButton";

const Tool = () => {
    const amplifyTheme: AmplifyTheme = {
        name: 'Auth Theme modelgeneratorAI',
        tokens: {
            colors: {
                brand: {
                    primary: {
                        '10': '#ff7a8a',
                        '80': '#ff7a8a',
                        '90': '#ff7a8a',
                        '100': '#ff7a8a',
                    },
                },
            },
            components: {
                tabs: {
                    item: {
                        _focus: {
                            color: {
                                value: '#111111',
                            },
                        },
                        _hover: {
                            color: {
                                value: '#28AA98',
                            },
                        },
                        _active: {
                            color: {
                                value: '#111111',
                            },
                        },
                    },
                },
            },
        },
    };
    const dispatch = useContext(ModelDispatchContext);

    useEffect(() => {
        fetchUsername().then((res) => {
            dispatch({ type: 'CHANGE_USERNAME', username: res });

        }).catch((error) => {
            console.log(error);
        });

    }, []);

    const fetchUsername = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            return user.username;
        } catch (error) {
            console.error('Error fetching username:', error);
        }
    };

    return (
        <>
            <AmplifyThemeProvider theme={amplifyTheme}>
                <Authenticator>
                    <Box pb={2}>
                        <ToolBar />
                    </Box>
                    <div>
                        <CaptionOptions />
                        <SubmitButton />
                        <ImagePreview />
                    </div>
                </Authenticator>
            </AmplifyThemeProvider>
        </>
    );
}

const ToolBar = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { username, imageUrl } = useContext(ModelContext);
    const [requestsLeftMsg, setRequestsLeftMsg] = useState("..");

    useEffect(() => {
        setRequestsLeft();
    }, [username, imageUrl]);

    const setRequestsLeft = async () => {
        //get credits from DB or else return 0
        const apiName = 'modelgencreditsapi';
        const path = '/credits/' + username;

        if (username !== "") {
            API.get(apiName, path, {}).then((response) => {
                setRequestsLeftMsg(response.credits.toString());
            }).catch((error) => {
                console.log(error);
                setRequestsLeftMsg("0");
            })
        }
    }


    return (
        <>
            <Box sx={{
                width: '100%',
                backgroundColor: 'white',
                display: 'flex',
                pb: 2,
            }}>
                <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
                </Box>

                <Box width={{ lg: '50%' }} textAlign={"center"} alignItems="center" pr={2} display={'flex'}>
                    <Typography variant="body1" alignSelf={"center"} textAlign={"right"}>Credits left: {requestsLeftMsg}</Typography>
                </Box>


                {isMobile ? (
                    <>
                    </>
                ) :
                    (
                        <>
                            <PurchaseButton />
                        </>
                    )}

                <Box width={{ lg: '50%' }} textAlign={"end"} pr={2} alignSelf={'center'} display={'flow-root'}>
                    <Button onClick={() => Auth.signOut()} color="secondary" variant='outlined' size={isMobile ? 'small' : 'medium'}>Sign Out</Button>
                </Box>

            </Box >
            <Divider />

        </>
    )
}

export default Tool;