import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const Faq = () => {

    return (
        <Box>
            <Typography variant="h4" pb={4} pt={2}>Frequently Asked Questions</Typography>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography variant="h6" component="h4" textAlign={"left"}>What is modelgenerator AI?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography textAlign={"left"}>
                        modelgenerator.ai is an AI-powered tool that generates highly realistic human stock photos for your needs. It's the perfect solution for anyone who needs cheap model photos for their social media, website or any other needs.
                        <br /><br />
                        You can use it too create realistic images of people, that don’t exist in reality. Plus, it's incredibly easy to use, making it the go-to solution for all your photo needs.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography variant="h6" component="h4" textAlign={"left"}>How does modelgenerator.ai work?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography textAlign={"left"}>
                        It's incredibly easy to use our AI model generator! You can select among different parameters to get the perfect persona for your needs. Start by selecting the display detail of the photo. For example portrait or full-body. Next choose the gender, ethnic group and clothing style. You can also choose the hair and eye color, age, body type of the person. Additionally you can set the scene of the photo. For example office, beach, city, nature, etc. Finally you can choose the activity of the person. For example working, exercising, etc.
                        <br /><br />
                        Finally, hit 'Generate Model' and in just a few seconds, a fitting stock photo will be generated for you. If it's not quite right, you can easily tweak the parameters and regenerate the image. This level of customization and ease-of-use makes model generator AI your go-to solution for all your model photo needs.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography variant="h6" component="h4" textAlign={"left"}>How much does it cost?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography textAlign={"left"} pb={2}>
                        You can try it out for free! 3 image generations are on us. We want you to find out yourself if it's worth it! Afterwards it costs only 4,99$ / month.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography variant="h6" component="h4" textAlign={"left"}>What if the photo doesn't fit?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography textAlign={"left"}>
                        You can regenerate the model image as often as you want. Every time a slightly different photo is generated. 
                        Therefore chances are basically zero that you won’t get a AI photo which fits to your preferences.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography variant="h6" component="h4" textAlign={"left"}>What is the best model photo generator?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography textAlign={"left"}>
                        model generator AI is the best model stock photo generator. It is the only AI photo generator that offers so many options to individualize your images. You can choose the display details, the gender, the ethnicity, age, clothing options, hair color, eye color, body type, scene of the photo and even activity. And you can regenerate the photo as often as you want. Every time a unique image is generated. We use AI technology to generate a model photo that fits your needs perfectly.
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </Box >
    )
}