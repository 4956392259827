import { Grid, Stack } from "@mui/material";
import BusinessPlan from "./businessPlan";
import FreePlan from "./freePlan";
import PersonalPlan from "./personalPlan";
import SubscriptionSwitch from "./subscriptionSwitch";


const PricingPlans = () => {

  return (
    <>
      <Stack direction="column" spacing={2}>
        <SubscriptionSwitch />
        <Grid container spacing={3} justifyContent="center">
          <PersonalPlan />
          <BusinessPlan />
        </Grid>
      </Stack>
    </>
  );

}

export default PricingPlans;