import Box from '@mui/material/Box';
import { useState } from 'react';
import { Contact } from './contact';
import { Button, Modal, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { ModelContext } from '../context/ModelContext';

export function Footer() {
  const [open, setOpen] = useState(false);
  const { stripeEmail } = useContext(ModelContext);
  const navigate = useNavigate();

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const handleClose = () => {
    setOpen(false);
  }

  const stripeManageSubscriptionUrl = "https://billing.stripe.com/p/login/8wM3fH5an7am0ve4gg" + "?prefilled_email=" + stripeEmail;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 16 }}>

      <Stack direction="column">
        <Box>
          <Stack direction="row" spacing={2}>

            {/* <Button onClick={() => setOpen(true)} >Contact</Button> */}
            <Button onClick={() => navigate('/privacy-policy')} >Privacy Policy</Button>
            <Button onClick={() => navigate('/imprint')} >Legal info</Button>
            <Button onClick={() => navigate('/terms-and-conditions')} >Terms and Conditions</Button>
            <Button variant="outlined" color="primary" href={stripeManageSubscriptionUrl}>Manage Subscription</Button>
          </Stack>
        </Box>
      </Stack>

      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={style}>
          <Contact />
        </Box>
      </Modal>
    </Box>
  );
}