import { Bolt, CheckBox, Language, SentimentSatisfied, UploadFile, Visibility, VisibilityOff } from "@mui/icons-material";
import { Grid, Paper, Typography, Button, Divider, Box, Stack, useTheme } from "@mui/material";
import { PurchaseButtonPersonal } from "../../../account/purchaseButtonPersonal";
import { useContext, useEffect, useState } from "react";
import { ModelContext } from "../../../../context/ModelContext";

const PersonalPlan = () => {
  const theme = useTheme();
  const { paymentPeriod } = useContext(ModelContext);

  const [price, setPrice] = useState("15€/Month");
  const [billed, setBilled] = useState("Billed monthly");

  const plan = {
    name: 'Personal',
    features: [
      'No Ads',
      '200 requests/month',
      'All languages:',
      'Max. image size: 5MB',
      'uses chatGPT 4',
      '3 days free trial'
    ],
  };

  useEffect(() => {
    if (paymentPeriod === "monthly") {
      setPrice('15€/Month');
      setBilled('Billed monthly');
    } else {
      setPrice('12€/Month');
      setBilled('Billed yearly');
    }

  }, [paymentPeriod]);

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Paper sx={{ padding: 2 }}>
        <Typography variant="h3" component="h3">
          {plan.name}
        </Typography>
        <Typography variant="h5">
          {price}
        </Typography>
        <Typography variant="caption">
          {billed}
        </Typography>
        <Box pt={2} pb={2}>
          <Divider />
        </Box>

        <Stack direction="row" spacing={1}>
          <VisibilityOff style={{ color: theme.palette.primary.main }} />
          <Typography textAlign={'left'}>
            {plan.features[0]}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={1}>
          <CheckBox style={{ color: theme.palette.primary.main }} />
          <Typography textAlign={'left'}>
            {plan.features[1]}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={1}>
          <Language style={{ color: theme.palette.primary.main }} />
          <Typography textAlign={'left'}>
            {plan.features[2]}:🇬🇧,🇩🇪,🇫🇷,🇪🇸,🇦🇪,🇨🇳,🇰🇷,🇯🇵,🇷🇺
          </Typography>
        </Stack>

        <Stack direction="row" spacing={1}>
          <UploadFile style={{ color: theme.palette.primary.main }} />
          <Typography textAlign={'left'}>
            {plan.features[3]}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={1}>
          <Bolt style={{ color: theme.palette.primary.main }} />
          <Typography textAlign={'left'}>
            {plan.features[4]}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={1} pb={2}>
          <SentimentSatisfied style={{ color: theme.palette.primary.main }} />
          <Typography textAlign={'left'}>
            {plan.features[5]}
          </Typography>
        </Stack>
        <PurchaseButtonPersonal />
      </Paper>
    </Grid>
  );
}

export default PersonalPlan;