import { Typography } from '@mui/material';
import React, { useEffect } from 'react';

const PrivacyPolicyPage: React.FC = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <Typography variant="h4" component="h1" pb={2}>Privacy Policy</Typography>

      <Typography variant="h5" component="h2" pb={2}>Information Collection</Typography>
      <Typography variant="body1" pb={4}>
        We do not collect any personal information from our users. We may collect non-personal data such as usage statistics, device information, and browser type for analytical purposes. By using this website you agree to consent to the collection of such information.
      </Typography>

      <Typography variant="h5" component="h2" pb={2}>Data Retention and Deletion</Typography>
      <Typography variant="body1" pb={2}>
        We follow strict data retention practices. All images are stored on cloud servers located in Germany. We retain uploaded images for a maximum of 24 hours and then they are permanently deleted from our servers.
      </Typography>

      <Typography variant="body1" pb={2}>
        Please be aware that some data may be transferred to servers located in the United States for processing purposes. We ensure that appropriate data protection measures are in place to safeguard your information in accordance with applicable data protection laws.
      </Typography>

      <Typography variant="body1" pb={2}>
        We may use third-party services (replicate.com, openai.com) to assist in generating image captions. These third-party services may have their own privacy policies and terms of use that you should review. We are not responsible for the privacy practices of any third parties.
      </Typography>

      <Typography variant="h5" component="h2" pb={2}>Cookies</Typography>
      <Typography variant="body1" pb={2}>
        We are not placing any cookies on this website.
        However please be aware that third parties, including Google, may place and read cookies on your browser or use web beacons to collect data as a result of ad serving on this website based on the user's prior visits to this website or other websites.
        <br />
        For detailed information please refer to Google's Privacy Policy: <a href="https://policies.google.com/technologies/partner-sites?hl=en">How we use data from websites or apps on or in which our services are used.</a>
        <br />
        You can opt out of personalized Google advertising by visiting <a href="https://adssettings.google.com/">Google Ads Settings</a>.
        <br />
        Alternatively, you can opt out of a third-party vendor's use of cookies for personalized advertising by visiting <a href="http://www.aboutads.info/choices/">www.aboutads.info</a>.
      </Typography>

    </div>
  );
};

export default PrivacyPolicyPage;