import { ThemeProvider } from '@mui/material';
import { ImageProvider } from './context/ModelContext';
import { BodyBox } from './components/bodybox';
import { CustomTheme } from './theme/theme';
import { Footer } from './components/footer';
import AboutUsPage from './components/about-us';
import { BrowserRouter } from 'react-router-dom';
import { Outlet, Route, Routes } from "react-router-dom";
import PrivacyPolicyPage from './components/privacy-policy';
import ImprintPage from './components/imprint';
import { CookiesProvider } from "react-cookie";
import Tool from './components/tool';
import "@aws-amplify/ui-react/styles.css";
import AccountPage from './components/account';
import { TopNav } from './components/header/components/topnav';
import Homepage from './components/homepage/homepage';
import AGBPage from './components/agb';

const App = () => {
  return (
    <CookiesProvider>
      <ThemeProvider theme={CustomTheme.getTheme()}>
        <ImageProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Wrapper />} >
                <Route path="/" element={<Homepage />} />
                <Route path="/tool" element={<Tool />} />
                <Route path="/about" element={<AboutUsPage />} />
                <Route path="/account" element={<AccountPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                <Route path="/imprint" element={<ImprintPage />} />
                <Route path="/terms-and-conditions" element={<AGBPage />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </ImageProvider>
      </ThemeProvider>
    </CookiesProvider>
  );
}

const Wrapper = () => {
  return (
    <>
      <TopNav />
      <Layout />
      <Footer />
    </>
  );
}

const Layout = () => {
  return (
    <>
      <BodyBox>
        <Outlet />
      </BodyBox>
    </>
  )
}

export default App;