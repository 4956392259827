import { useContext } from "react";
import { Box, Skeleton } from "@mui/material";
import { ModelContext } from "../../../context/ModelContext";

const ImagePreview = () => {
    const { imageUrl, imageUploading, imageUploaded } = useContext(ModelContext);

    if (imageUploading === true) {
        return (
            <Box justifyContent="center" display="flex">
                <Skeleton sx={{ height: 250, width: 300 }} />
            </Box>
        );
    } else if (imageUploading === false && imageUploaded === true) {
        return (
            <Box component="img" sx={{ height: 500, alignSelf: "center", pt: 4 }} alt="uploaded image" src={imageUrl} />
        )
    } else {
        return (
            <Box />
        )
    }
};

export default ImagePreview;