import { useContext, useEffect, useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { ModelContext, ModelDispatchContext } from "../../../context/ModelContext";
import { API } from 'aws-amplify';

const SubmitButton = () => {
    const { getCaptionButtonDisabled, responseCaption, username, imageUrl } = useContext(ModelContext);
    const { bildAusschnitt, gender, race, clothes, age, skinColor, hairColor, eyes, bodyType, scene, activity } = useContext(ModelContext);
    const dispatch = useContext(ModelDispatchContext);

    const [tryAgaintime, setTryAgaintime] = useState("");

    const checkRequestsLeft = async () => {
        if ((await getCredits()) < 1) {
            dispatch({ type: "CHANGE_BUTTON", getCaptionButtonDisabled: true });
            setTryAgaintime("Your account request limit has been reached. Start a subscription to keep using modelgenerator.");
        }
    }

    useEffect(() => {
        //enable button if all fields are filled
        if (gender !== "" && race !== "" && clothes !== "") {
            dispatch({ type: "CHANGE_BUTTON", getCaptionButtonDisabled: false });
        }
    }, [gender, race, age, clothes, skinColor, hairColor, eyes, bodyType, scene]);

    useEffect(() => {
        if (username !== "") {
            checkRequestsLeft()
        }

    }, [username, imageUrl]);

    const getCaption = async () => {
        dispatch({ type: "CHANGE_CAPTION", responseCaption: "Loading... can take up to a minute" });
        dispatch({ type: "CHANGE_LOADING", responseLoading: true });
        dispatch({ type: "CHANGE_BUTTON", getCaptionButtonDisabled: true });

        const apiName = 'modelai'; // replace this with your API Gateway API name
        const path = '/generate';
        const myInit = {
            body: {
                bildAusschnitt: bildAusschnitt,
                gender: gender,
                race: race,
                age: age,
                clothes: clothes,
                skinColor: skinColor,
                hairColor: hairColor,
                eyes: eyes,
                bodyType: bodyType,
                scene: scene,
                activity: activity
            },
            headers: {
                'Content-Type': 'application/json',
            },
        };

        console.log(myInit.body);

        dispatch({ type: "UPLOAD", imageUploading: true, imageUploaded: false });
        API.post(apiName, path, myInit).then((response) => {
            if (response === "error") {
                dispatch({ type: "CHANGE_CAPTION", responseCaption: "Error: " + "Currently there are some issues. Please try again." });
                dispatch({ type: "CHANGE_LOADING", responseLoading: false });
                dispatch({ type: "CHANGE_BUTTON", getCaptionButtonDisabled: false });
            } else {
                dispatch({ type: "CHANGE_CAPTION", responseCaption: "Generated Model:" });
                dispatch({ type: "CHANGE_LOADING", responseLoading: false });
                dispatch({ type: "CHANGE_BUTTON", getCaptionButtonDisabled: false });
                dispatch({ type: "CHANGE_URL", imageUrl: response });
                dispatch({ type: "UPLOAD", imageUploading: false, imageUploaded: true });
                decrementCredits();
            }
        }).catch((error) => {
            console.log(error);
            dispatch({ type: "CHANGE_CAPTION", responseCaption: "Error: " + "Currently there are some issues. Please try again." });
            dispatch({ type: "CHANGE_LOADING", responseLoading: false });
            dispatch({ type: "CHANGE_BUTTON", getCaptionButtonDisabled: false });
        });
    }

    const decrementCredits = async () => {
        const apiName = 'modelgencreditsapi';
        const path = '/credits/' + username + '/decrement-credits';

        API.put(apiName, path, {}).then((response) => {
            if (response === "error") {
                console.log("Error: decrement captions in DB failed")
            }
        }).catch((error) => {
            console.log(error);
        });
    }


    const getCredits = async () => {
        //get credits from DB
        const apiName = 'modelgencreditsapi';
        const path = '/credits/' + username;


        try {
            const response = await API.get(apiName, path, {});
            if (response === "error") {
                console.log("Error: increment captions in DB failed");
                return 0;
            } else {
                return response.credits;
            }
        } catch (error) {
            console.log(error);
            return 0;
        }
    }


    return (
        <Stack direction="column" spacing={4}>
            <Box>
                <Button disabled={getCaptionButtonDisabled} variant="contained" component="label" onClick={getCaption} >{responseCaption !== "" ? "Regenerate Model" : "Generate Model"}</Button>
            </Box>
            <Box display={getCaptionButtonDisabled == false ? "none" : "inline-block"}>
                <Typography variant="body1" color="red" mt={1} textAlign={"center"}>{tryAgaintime}</Typography>
            </Box>
        </Stack>
    );
}

export default SubmitButton;