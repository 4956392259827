import { Button } from "@mui/material";
import axios from "axios";
import { useContext } from "react";
import { ModelContext } from "../../context/ModelContext";
import { useCookies } from "react-cookie";

export const PurchaseButtonPersonal = () => {
    const { username, paymentPeriod } = useContext(ModelContext);
    const [cookies] = useCookies(['referalID']);

    let referalId = 'none';
    (cookies.referalID ? referalId = cookies.referalID : referalId = 'none')

    //prod:
    const serverUrl = "https://80r61llt1b.execute-api.eu-central-1.amazonaws.com/prod";
    //test:
    // const serverUrl = "http://localhost:3001";

    const handleCheckout = async (event: any) => {
        event.preventDefault();
        try {
            const response = await axios.post(serverUrl + '/payment/create-checkout-session', {
                username: username,
                plan: "personal",
                period: paymentPeriod,
                referalId: referalId,
            });

            console.log(response.data);
            window.location.href = response.data.url;
        } catch (error) {
            console.log(error)
        }
    };

    return (
        <>
            <form onSubmit={handleCheckout} method="POST">
                <Button variant="contained" color="secondary" type="submit">
                    Purchase Personal Plan
                </Button>
            </form>
        </>
    )
}