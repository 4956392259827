import React, { useContext, useEffect, useState } from 'react';
import { Box, Chip, FormControlLabel, Switch, Typography } from '@mui/material';
import { ModelContext, ModelDispatchContext } from '../../../../context/ModelContext';

const SubscriptionSwitch = () => {
  const [isYearly, setIsYearly] = useState(true);

  const dispatch = useContext(ModelDispatchContext);

  useEffect(() => {
    dispatch({ type: "CHANGE_PAYMENT_PERIOD", paymentPeriod: "yearly" });
  }, []);

  const handleChange = () => {
    setIsYearly(!isYearly);
    dispatch({ type: "CHANGE_PAYMENT_PERIOD", paymentPeriod: !isYearly ? "yearly" : "monthly" });
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box mr={2}>
        <Typography variant="body1">Pay Monthly</Typography>
      </Box>
      <FormControlLabel
        control={<Switch color="primary" />}
        checked={isYearly}
        onChange={handleChange}
        label=""
      />
      <Box>
        <Typography variant="body1">Pay Yearly</Typography>
      </Box>
      <Chip label="Save 20%!" color="info" sx={{ ml: 1 }} />
    </Box>
  );
};

export default SubscriptionSwitch;
