import { Box, Toolbar, Typography, useMediaQuery, useTheme, Button } from '@mui/material';
import { PurchaseButton } from '../../account/purchaseButton';

export const TopNav = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Toolbar sx={{ backgroundColor: theme.palette.secondary.main }}>
            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', }}>
                {isMobile ? (
                    <>
                        <Typography variant="body1" pr={2} color={"white"}>👤modelgeneratorAI</Typography>
                    </>
                ) :
                    (
                        <>
                            <Typography variant="body1" pr={2} color={"white"}>👤modelgeneratorAI</Typography>
                        </>
                    )
                }
            </Box>
            {!isMobile && (
                <Box>
                    <Typography variant="body1" color={"white"}>Get captions for your model: <a href="https://imagetocaption.ai" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: theme.palette.primary.light }}>imagetocaption.ai</a>
                    </Typography>
                </Box>
            )}
        </Toolbar>
    );
}