import { Typography } from '@mui/material';
import React, { useEffect } from 'react';

const ImprintPage: React.FC = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <div>
            <Typography variant="h4" component="h1" pb={2}>Legal Information</Typography>

            <Typography variant="h5" component="h2" pb={2}>Information according to § 5 TMG</Typography>
            <Typography variant="body1" pb={4}>
                imagetocaption GmbH
                <br />
                Staufeneckstr. 17
                <br />
                70469 Stuttgart
                <br />
                Germany
            </Typography>

            <Typography variant="h5" component="h2" pb={2}>Contact</Typography>
            <Typography variant="body1" pb={4}>
                e-mail: info@imagetocaption.ai
            </Typography>

            <Typography variant="h5" component="h2" pb={2}>Sales tax ID</Typography>
            <Typography variant="body1" pb={2}>
                Sales tax identification number according to § 27 a Umsatzsteuergesetz:
                <br />
                DE367222175
            </Typography>

            <Typography variant="h5" component="h2" pb={2}>Business registration</Typography>
            <Typography variant="body1" pb={2}>
                The business registration according to §14 or §55c GewO was granted on 18.01.2024 by Amtsgericht Stuttgart.
            </Typography>

            <Typography variant="h5" component="h2" pb={2}>Consumer dispute resolution/universal arbitration board</Typography>
            <Typography variant="body1" pb={2}>
                We are not willing or obliged to participate in dispute resolution proceedings before a
                consumer arbitration board.
            </Typography>

            <Typography variant="h5" component="h2" pb={2}>Liability for content</Typography>
            <Typography variant="body1" pb={2}>
                As a service provider, we are responsible for our own content on these pages in accordance with § 7 para.1 TMG (German Telemedia Act) under the
                general laws. According to §§ 8 to 10 TMG, we are not obligated to disclose the
                to monitor transmitted or stored information from third parties or to investigate circumstances
                or to investigate circumstances that indicate illegal activity.
                Obligations to remove or block the use of information in accordance with general
                laws remain unaffected. However, any liability in this respect is only possible from the time of
                knowledge of a concrete violation of the law. If we become aware of corresponding infringements, we will
                infringements, we will remove this content immediately.
            </Typography>


            <Typography variant="h5" component="h2" pb={2}>Liability for links</Typography>
            <Typography variant="body1" pb={2}>
                Our offer contains links to external websites of third parties, on whose contents we have no influence.
                Therefore, we cannot assume any liability for these external contents. For the contents of the
                The respective provider or operator of the pages is always responsible for the content of the linked pages. The linked
                pages were checked for possible legal violations at the time of linking. Illegal contents
                were not recognizable at the time of linking.
                However, a permanent control of the linked pages is not reasonable without concrete evidence of an infringement.
                infringement is not reasonable. If we become aware of any infringements of the law, we will remove such links immediately.
                such links immediately.
            </Typography>

            <Typography variant="h5" component="h2" pb={2}>Copyright</Typography>
            <Typography variant="body1" pb={2}>
                The contents and works created by the site operators on these pages are subject to German copyright law.
                copyright law. The reproduction, processing, distribution and any kind of exploitation outside the limits of copyright
                of the copyright require the written consent of the author or creator.
                Downloads and copies of this site are only permitted for private, non-commercial use.
                Insofar as the content on this site was not created by the operator, the copyrights of third parties are respected.
                respected. In particular, third-party content is identified as such. Should you nevertheless become aware of a
                copyright infringement, please inform us accordingly. At
                We will remove such content immediately upon becoming aware of any infringements.
            </Typography>

        </div>
    );
};

export default ImprintPage;