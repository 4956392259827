import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { useContext } from 'react';
import { ModelContext } from '../../context/ModelContext';
import PricingPlans from '../tool/components/pricingPlans';

const AccountPage = () => {
    const { username, stripeEmail, plan } = useContext(ModelContext);

    const stripeManageSubscriptionUrl = "https://billing.stripe.com/p/login/8wM3fH5an7am0ve4gg" + "?prefilled_email=" + stripeEmail;

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5">Account Details</Typography>
                            <Typography variant="body1">Username: {username}</Typography>
                            <Typography variant="body1">Credits: {plan}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <>
                        <Typography variant="h4">Top up credits:</Typography>
                        <PricingPlans />
                        <Button variant="contained" color="primary" href={stripeManageSubscriptionUrl}>Manage Payments</Button>
                    </>
                </Grid>
            </Grid>
        </div>
    );
};

export default AccountPage;
