import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { SoFar } from '../tool/components/soFar';
import { InAction } from '../tool/components/inAction';
import { Testimonials } from './testimonials';
import { Faq } from './faq';
import LaunchIcon from '@mui/icons-material/Launch';

const Homepage: React.FC = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box pb={4}>
            {(isMobile ?
                <Box pb={4}>
                    <Typography variant="h4" component="h1" pb={2} textAlign={"center"}>Create realistic AI model photos in seconds!</Typography>

                    <Button size="large" variant='contained' component="a" sx={{ p: 2 }} style={{ backgroundColor: "green", color: "white" }} href='/tool'>Get started for free&#160;&#160;
                        <LaunchIcon />
                    </Button>

                    <InAction />
                    <SoFar />

                    <Button size="large" variant='contained' component="a" sx={{ p: 2, m: 2 }} style={{ backgroundColor: "green", color: "white" }} href='/tool'>Get started for free&#160;&#160;
                        <LaunchIcon />
                    </Button>

                    <Testimonials />
                    
                    <Faq />
                </Box> :
                <Box pb={4}>
                    <Typography variant="h4" component="h1" pb={2} textAlign={"center"}>Create realistic AI model photos in seconds!</Typography>
                    <Box pb={2} >
                        <Button size="large" variant='contained' component="a" sx={{ mb: 2, p: 2 }} style={{ backgroundColor: "green", color: "white" }} href='/tool'>Get started for free&#160;&#160;
                            <LaunchIcon />
                        </Button>
                    </Box>
                    <SoFar />
                    <InAction />
                    <Testimonials />
                    <Faq />

                </Box>
            )}
            <Box pb={8} >
                <Button style={{ backgroundColor: '#ff7a8a', color: "white" }} href='/tool'>Register / Log in</Button>
            </Box>

        </Box >
    );
};

export default Homepage;
