import { createTheme, Theme, ThemeOptions } from "@mui/material/styles";

const customTheme: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#ff7a8a',
            light: '#ffadb7',
            dark: '#ff475d',
            contrastText: '#195588',
        },
        secondary: {
            main: '#113a5d',
        },
    },
    typography: {
        fontFamily: 'Manrope',
    },
    shape: {
        borderRadius: 10,
    },
};


const MODELAITheme = createTheme(customTheme)


export const CustomTheme = {
    getTheme: (): Theme => {
        return MODELAITheme;
    }
}